// src/CountdownTimer.js
import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Minutes: Math.floor((difference / 1000 / 60) % 60),
        Seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = null; // Countdown has ended
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (!timeLeft) return; // Stop timer if countdown has ended

    const timer = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft();
      setTimeLeft(updatedTimeLeft);

      if (!updatedTimeLeft) {
        clearInterval(timer); // Clear interval when countdown ends
      }
    }, 1000);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [timeLeft, targetDate]);

  if (!timeLeft) {
    return <span className="text-red-500 font-semibold">This mentorship has already started</span>;
  }

  return (
      <>
     <div>Available for the next:</div>
    <div className="flex justify-center cursor-default sm:justify-center my-2">
      {Object.keys(timeLeft).map((unit, index) => (
        <div key={index} className="mx-2">
          <div className="text-2xl font-bold">{timeLeft[unit]}</div>
          <div className="text-sm">{unit}</div>
        </div>
      ))}

    </div>
        </>
  );
};

export default CountdownTimer;
